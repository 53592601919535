import React, { useEffect, useState } from 'react';
import { Redirect, Route, Switch, useLocation } from 'react-router-dom';
import { CssBaseline } from '@mui/material';
import { Div100vh, GwRefirect } from './components';
import PrivateRoutes from './containers/PrivateRoutes';
import AuthContainer from './containers/Login';
import {
  loadLanguageAsync,
  loadUserProfileAsync,
  loginTinkoffIdAsync,
  selectCurrentLang,
  selectLoading,
  selectLoadingLanguage,
  selectProfile,
  selectUserUuid,
  setCurrentLang,
} from './redux/profileDataSlice';
import { useAppDispatch, useAppSelector, useAuth } from './utils/hooks';
import { AUTH, REDIRECT_TEST } from './utils/config';
import './App.css';
import splash from './static/splash.png';
import { loadOrderStateAsync } from './redux/loungeOrderPageSlice';

function App() {
  const dispatch = useAppDispatch();
  const { pathname } = useLocation();

  const { logout } = useAuth();
  const search = useLocation().search;
  const code = new URLSearchParams(search).get('code');
  const state = new URLSearchParams(search).get('state');

  const profile = useAppSelector(selectProfile);
  const profileLoading = useAppSelector(selectLoading);
  const currentLang = useAppSelector(selectCurrentLang);
  const loadingLanguage = useAppSelector(selectLoadingLanguage);
  const userUuid = useAppSelector(selectUserUuid);

  const [viewSplash, setViewSplash] = useState(true);
  const initialize = async () => {
    setViewSplash(true);
    if (code && state === localStorage.getItem('state')) {
      await dispatch(loginTinkoffIdAsync(code));
      localStorage.removeItem('state');
      return;
    }
    const user = await dispatch(loadUserProfileAsync());
    if (!user && pathname !== AUTH && pathname !== REDIRECT_TEST) {
      logout();
    }
    setViewSplash(false);
  };

  useEffect(() => {
    initialize();
  }, []);

  useEffect(() => {
    if (!profileLoading) {
      dispatch(loadUserProfileAsync());
    }
  }, [userUuid]);

  useEffect(() => {
    if (!profileLoading) {
      dispatch(setCurrentLang(profile?.language || navigator.language.slice(0, 2)));
    }
  }, [profile, profileLoading]);

  useEffect(() => {
    if (!currentLang) {
      return;
    }
    const controller = new AbortController();
    dispatch(loadLanguageAsync(null, controller.signal));
    dispatch(loadOrderStateAsync(null, controller.signal));
    return () => {
      controller.abort();
    };
  }, [currentLang]);

  useEffect(() => {
    if (/\?screen=lounge&id=.+/.test(search)) {
      const isiPhone = /iPhone/i.test(navigator.userAgent);
      if (isiPhone) {
        window.location.href = 'https://apps.apple.com/app/id6444651677';
      } else {
        window.location.href =
          'https://play.google.com/store/apps/details?id=com.askan.gwp_client_application';
      }
    }
  }, [search]);

  return (
    <>
      <Div100vh>
        <CssBaseline />
        {viewSplash ? (
          <div style={{ height: '100vh', maxWidth: 600, margin: '0 auto' }}>
            <img src={splash} alt="" style={{ width: '100%', objectFit: 'contain' }} />
          </div>
        ) : profile ? (
          <PrivateRoutes />
        ) : (
          <Switch>
            <Route exact path={AUTH} component={AuthContainer} />
            <Route path={REDIRECT_TEST} component={GwRefirect} />
            <Redirect to={AUTH} />
          </Switch>
        )}
      </Div100vh>
    </>
  );
}

export default App;
