import { useEffect, useRef } from 'react';

const href =
  'https://greywallpasslinks.page.link/?efr=0&ibi=com.alid.greywallpass&apn=com.askan.gwp_client_application&link=https%3A%2F%2Fgreywallpass.com%2FfinishedPayment';
export const GwRefirect = () => {
  const ref = useRef<HTMLAnchorElement | null>(null);
  useEffect(() => {
    if (ref.current) {
      setTimeout(() => ref.current?.click(), 2000);
    }
  }, []);
  // eslint-disable-next-line jsx-a11y/anchor-has-content
  return <a ref={ref} href={href}></a>;
};
